















































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import { intervalToDuration, format } from 'date-fns';

import { VueEditor } from 'vue2-editor';

import BookService from '@/services/Book/BookService';

import ErrorLoading from '@/components/ErrorLoading/ErrorLoading.vue';

import CommentsOrAnnotationsLoading from '../CommentsOrAnnotationsLoading/CommentsOrAnnotationsLoading.vue';
import ReplyComment from './components/ReplyComment.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import { InterfaceComments } from './interface';

@Component({
  components: {
    VueEditor,
    CommentsOrAnnotationsLoading,
    ErrorLoading,
    ReplyComment,
  },
})

export default class Comments extends Vue {
  @Prop() subject!: string;
  @Prop() idTopic!: string;
  @Prop() contextID!: string;
  @Prop() fileID!: string;
  @Prop() loading!: boolean;

  private listComments: Array<Record<string, any>> = [];
  private content = '';
  private isLoadingComments = true;
  private errorLoading = false;

  private BookService = new BookService();

  private customToolbar = ['bold', 'italic', 'link', { list: 'ordered' }, { list: 'bullet' }, 'blockquote'];

  private time = '00:00';
  private timeInterval = 0;

  mounted() {
    this.timeInterval = setInterval(this.currentTime);
    this.getComments();
  }

  destroyed() {
    clearInterval(this.timeInterval);
  }

  @Watch('subject')
  @Watch('fileID')
  async getComments() {
    try {
      this.isLoadingComments = true;

      if (this.subject && this.fileID) {
        this.listComments = await this.BookService.getComments(this.subject, this.fileID);
      }
    } catch (error) {
      this.errorLoading = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os comentários.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingComments = false;
    }
  }

  getTime(date: string | Date) {
    const dateWithoutZ = typeof date === 'string' ? date.replace('Z', '') : null;
    const commentDate = new Date(dateWithoutZ || date);
    const currentDate = new Date();

    const dateRange: any = intervalToDuration({
      start: commentDate,
      end: currentDate,
    });

    const dateWithin24hours = dateRange
      && dateRange.days <= 0
      && dateRange.months <= 0
      && dateRange.years <= 0;

    if (dateWithin24hours) {
      const hour = String(commentDate.getHours()).padStart(2, '0');
      const minutes = String(commentDate.getMinutes()).padStart(2, '0');

      return `${hour}:${minutes}`;
    }

    return `${format(commentDate, 'dd/MM/yyyy')}`;
  }

  currentTime() {
    const date = String(new Date());
    this.time = this.getTime(date);
  }

  setName(comment: Record<string, any>) {
    const { idstudent } = this.$store.getters.profile;

    if (!comment?.profile) return 'Oculto';

    if (comment?.profile?.idStudent === idstudent) return 'Você';

    return comment?.profile?.nickname;
  }

  setStatusText(status: number) {
    return (status === 0 || status === 3) ? 'Aguardando aprovação' : 'Comentário moderado';
  }

  async setComment(comment: string) {
    try {
      if (!this.content) {
        return;
      }

      this.setNewComment(comment);

      this.content = '';

      await this.BookService.postComments(this.subject, this.contextID, this.idTopic, comment, this.fileID);

      this.setTrackAmplitude();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar o comentário.',
        status: 'error',
      });
      console.error(error);
    }
  }

  setNewComment(comment: string) {
    const { nickname, idstudent } = this.$store.getters.profile;

    const newComment: InterfaceComments = {
      content: comment,
      registry_date: new Date(),
      status: 0,
      profile: { nickname, idStudent: idstudent },
    };

    this.listComments.push(newComment);
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Comments',
          local: 'Comentar',
        },
      },
    });
  }
}
